<template>
    <v-layout class="fill-height" align-center justify-center>
        <login-form id="form" @emailandpassword="submit"  @google="loginThroughGoogle" @forgot="reset"
                    :register="register" @register="register_submit" :loading="loginLoading"
        >

        </login-form>
        <v-snackbar top v-model="snackbar">{{snacktext}}</v-snackbar>
    </v-layout>
</template>

<script>
    import firebase from 'firebase/app';
    import LoginForm from "@/components/LoginForm";

    export default {
        name: "login",
        components: {LoginForm},
        props: {
            register: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                form: {
                    email: "",
                    password: ""
                },
                snackbar: false,
                snacktext: '',
                loginLoading: false,
            };
        },
        methods: {
            getErrorText(error){
                if(error.code == "Auth/email-already-exists"){
                  return "Die bereitgestellte E-Mail wird bereits von einem vorhandenen Benutzer verwendet."
                }
                else if(error.code == "Auth/internal-error") {
                  return "Der Server hat einen internen Fehler. Bitte versuchen Sie es später noch einmal."
                }
                else if(error.code == "Auth/invalid-email") {
                  return "Die E-Mail Adresse ist nicht gültig."
                }
                else if(error.code == "Auth/user-not-found") {
                  return "Es wurde kein Benutzer mit diesen Logindaten gefunden."
                }
                else return "Es gab einen Fehler."

            },
            submit({email, password}) {
                this.loginLoading = true
                firebase
                    .auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(() => {
                      this.loginLoading = false
                      this.$router.replace({ name: "menu" });
                    })
                    .catch(error => {
                      this.loginLoading = false
                      this.snackbar = true
                      this.snacktext = this.getErrorText(error)
                    });
            },
            loginThroughGoogle() {
              this.loginLoading = true
              let provider = new firebase.auth.GoogleAuthProvider();
                firebase.auth().signInWithPopup(provider).then(() => {
                  this.loginLoading = false
                  this.$router.replace({ name: "menu" });
                }).catch(function(error) {
                  this.loginLoading = false
                  this.snackbar = true
                  this.snacktext = this.getErrorText(error)
                });
            },
            signOut() {
                firebase
                    .auth()
                    .signOut()
                    .then(() => {
                        this.$router.replace({
                            name: "Menu"
                        });
                    });
            },
            register_submit({email, password}) {
              this.loginLoading = true
              firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then(() => {
                      this.loginLoading = false
                      this.$router.replace({ name: "menu" });
                    })
                    .catch(error => {
                      this.loginLoading = false
                      this.snackbar = true
                        this.snacktext = this.getErrorText(error)
                    });
            },
            reset({email}) {
                let auth = firebase.auth();

                auth.sendPasswordResetEmail(email).then(() => {
                    this.snackbar = true
                    this.snacktext = 'Eine E-Mail zum Zurücksetzen des Passworts wurde versandt.'
                }).catch((error) => {
                    this.snackbar = true
                    this.snacktext = this.getErrorText(error)
                });
            }
        }
    };
</script>
<style>

</style>